<template>
  <div>
    <v-app>
      <v-container fluid>
        <v-row>
          <navigation class="sidebar__main" :class="drawerNav == true ? 'sidebar__main_open' : ''" v-if="role == 'admin'"></navigation>
          <div :class="drawerNav == true ? 'overlay' : ''" @click="drawerNav = !drawerNav"></div>

          <navigationSeller class="sidebar__main" :class="drawerNav == true ? 'sidebar__main_open' : ''" v-if="role == 'seller'"></navigationSeller>
          <div :class="drawerNav == true ? 'overlay' : ''" @click="drawerNav = !drawerNav"></div>


          <v-app-bar app elevation="0" height="100" class="appBar bgNone navbar__main hidden-sm-and-down" color="white">
            <v-col cols="12">
              <div class="d-flex">

                <p style="font-size: 29px;" class="welcome mt-10 font-weight-medium hidden-sm-and-down">
                  Assalamu alaykum!
                </p>

                <p class="magnatInfo mt-14 ml-5 hidden-sm-and-down"
                   style="font-size: 15px;line-height: 23px;color: #91A3B8;">
                  Magnat boshqaruv paneliga xush kelibsiz
                </p>

                <v-spacer class="hidden-sm-and-down"></v-spacer>

                <div class="d-flex mr-10 hidden-sm-and-down">

                  <v-menu offset-y content-class="elevation-15 rounded-lg hidden-sm-and-down">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon plain v-bind="attrs" v-on="on" class="mr-2 hidden-sm-and-down" @click="openReminder">
                        <v-badge color="blue" :content="dataReminder.length">
                          <svg width="24" height="28" viewBox="0 0 24 28" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12.0201 6.91C8.71009 6.91 6.02009 9.6 6.02009 12.91V15.8C6.02009 16.41 5.76009 17.34 5.45009 17.86L4.30009 19.77C3.59009 20.95 4.08009 22.26 5.38009 22.7C9.69009 24.14 14.3401 24.14 18.6501 22.7C19.8601 22.3 20.3901 20.87 19.7301 19.77L18.5801 17.86C18.2801 17.34 18.0201 16.41 18.0201 15.8V12.91C18.0201 9.61 15.3201 6.91 12.0201 6.91Z"
                              stroke="#91A3B8" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                            <path
                              d="M13.8699 7.2C13.5599 7.11 13.2399 7.04 12.9099 7C11.9499 6.88 11.0299 6.95 10.1699 7.2C10.4599 6.46 11.1799 5.94 12.0199 5.94C12.8599 5.94 13.5799 6.46 13.8699 7.2Z"
                              stroke="#91A3B8" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                              stroke-linejoin="round"/>
                            <path
                              d="M15.02 23.06C15.02 24.71 13.67 26.06 12.02 26.06C11.2 26.06 10.44 25.72 9.90002 25.18C9.36002 24.64 9.02002 23.88 9.02002 23.06"
                              stroke="#91A3B8" stroke-width="1.5" stroke-miterlimit="10"/>
                          </svg>
                        </v-badge>

                      </v-btn>
                    </template>

                    <v-list style="background: white" v-show="reminderOpen" class="hidden-sm-and-down">
                      <v-list-item-group style="width: 400px; height: 300px;background: white" v-model="selected"
                                         active-class="primary--text" multiple>
                        <v-col style="background: white; flex-wrap: nowrap" v-for="(item,index) in dataReminder"
                               :key="index">
                          <p class="font-weight-medium pl-2 pr-2">
                            {{ item.description }}
                          </p>
                          <h6 class="ml-2">{{
                              item.created_at 
                            }}</h6>
                        </v-col>
                      </v-list-item-group>
                    </v-list>

                  </v-menu>

                  <v-text-field hide-details class="hidden-sm-and-down" prepend-inner-icon="mdi-magnify" outlined dense
                   label="Qidiruv..." v-model="search"></v-text-field>
                </div>
              </div>
            </v-col>

          </v-app-bar>




          <v-navigation-drawer right app class="sidebar pa-1" color="white" v-if="this.$route.path == '/dashboard'">
            <div class="text-center px-12">
              <v-dialog
                v-model="reminderModal"
                width="500"
                style="position: absolute;margin-bottom: 250px;"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row justify="center" class="mt-5">
                    <div style="padding:1rem;">
                      <v-date-picker :min="getMinDate" :max="getMaxDate" scrollable v-bind="attrs" v-on="on"
                                     v-model="created_at" elevation="0"
                                     class="calendar"></v-date-picker>
                    </div>

                  </v-row>
                </template>
                <v-card class="pa-5">
                  <h2>Eslatma qo'shish</h2>
                  <br>
                  <v-textarea v-model="description" placeholder="Misol: Ertangi ishlarim" outlined></v-textarea>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" lass="mr-4" large elevation="0"
                           @click="addReminder"
                           style="background: #507BE8;border-radius: 14px;width: 150px; height: 50px">
                      Eslatma qo'shish
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>

            <div class="listNav">
              <div class="d-flex pa-5 mt-2 justify-space-between">
                <p style="font-size: 19px" class="font-weight-medium mt-2">Premium mijozlar</p>
                <router-link to="/clients">
                  <img class="mb-5" src="@/assets/Go.svg" alt="go">
                </router-link>
              </div>

              <v-list class="rounded-lg pa-2" style="margin-top: -35px;" flat>
                <div v-if="!(Object.keys(premiumCustomer).length === 0)">
                  <v-list-item-group style="height: 550px; overflow-y: scroll;position: relative;"
                                     active-class="activeList">
                    <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%)">
                      <Loader v-if="loadingContent"></Loader>
                    </div>

                    <v-list-item v-for="(item, i) in premiumCustomer" :key="i"
                                 class=""
                                 :class="[item.bg ? 'listActiveLi' : '']">
                      <v-list-item-icon
                        :class="{'circleAvatar':i % 2 ===1,'circleAvatar1':i % 2 ===0,'circleAvatar2':i % 3 ===1,}">
                        <span> {{ item.customer.name[0] }} </span>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.customer.name"></v-list-item-title>
                      </v-list-item-content>
                      <v-spacer></v-spacer>
                      <p>{{ item.amount }}</p>
                    </v-list-item>
                  </v-list-item-group>
                </div>
                <Nodata v-else></Nodata>
              </v-list>
            </div>

            <div style="margin-top: 100px"></div>

          </v-navigation-drawer>

        </v-row>
      </v-container>
      <div class="container__main">
        <router-view></router-view>
      </div>
    </v-app>
  </div>
</template>

<script>
import axios from "../plugins/axios";
import { mapState } from 'vuex'
// import {mapGetters} from 'vuex'
import navigation from '../components/NavigationDrawer'
import navigationSeller from '../components/NavigationDrawerSeller'
import moment from "moment-timezone";
import Loader from '../components/loader/ProgressCircular'
import Nodata from '../components/base/NoDate'
export default {
  components: {
    navigation, navigationSeller,
    Loader, Nodata 
  },
  name: "Home.vue",
  data() {
    return {
      loadingContent: false,
      reminderOpen: false,
      dataReminder: {},
      reminderCount: '',
      premiumCustomer: '',
      selectionItem: [],
      selected: [],
      drawerNavLeft: false,
      // drawerNav: false,
      e1: '1',
      stateSellorDebts: '',
      items: [
        {text: 'Real-Time', icon: 'mdi-clock', bg: true},
        {text: 'Audience', icon: 'mdi-account', bg: false},
        {text: 'Conversions', icon: 'mdi-flag', bg: true},
        {text: 'Real-Time', icon: 'mdi-clock', bg: false},
        {text: 'Conversions', icon: 'mdi-flag', bg: true},
        {text: 'Conversions', icon: 'mdi-flag', bg: false},
      ],
      disabledBtn: false,
      picker: '',
      reminderModal: false,
      created_at: '',
      description: '',
      desc: {description: '', created_at: ''},
      routeIcons: [],
      search: '',
      role: '',
    }
  },
  beforeCreate() {
    setTimeout(() => {
      if (localStorage.getItem('token')) {
        return localStorage.removeItem('token')
      }
    }, 4 * 60 * 60 * 1000)
  },
  computed: {
    ...mapState({
      // drawerNav: state => state.common.drawerNav

    }),
    drawerNav: {
        get: function() {
            return this.$store.state.common.drawerNav
        },
        set: function(newValue) {
            return this.$store.state.common.drawerNav = newValue
        }
    },
    getMinDate() {
      let cvartal = localStorage.getItem("cvartal");
      return cvartal.substring(0, 10);
    },

    getMaxDate() {
      let cvartal = localStorage.getItem("cvartal");
      return cvartal.substring(11);
    },
    
    
    // ...mapGetters(['routeIcons', 'reminders', 'premium_customer']),
  },
  watch: {
    reminderCount() {
      return this.dataReminder.length;
    },
    created_at() {
      this.reminderModal = true
    }

  },
  mounted() {
    this.role = localStorage.getItem('role')
    

    this.loadingContent = true;
    let cvartal = localStorage.getItem("cvartal");
    this.$axios.get("/get-premium-customer?cvartal=" + cvartal).then(response => {
      this.premiumCustomer = response.data;
      this.loadingContent = false;
    });
    this.openReminder();
    this.getReminderData();
    // this.$store.dispatch('loadReminder')
    // this.$store.dispatch('loadPremium')
  },
  methods: {
    drawerOpen(){
      // drawerNav = !drawerNav
    },
   
    clearDaily() {
      if (localStorage.getItem('token')) {
        return localStorage.getItem('token').clear()
      }
    },

    addReminder() {
      // this.reminderModal = true;
      this.desc.description = this.description
      this.desc.created_at = this.created_at
      axios.post(process.env.VUE_APP_API_BASE_URL + "/reminder", this.desc).then(response => {
        this.getReminderData();

        this.description = "";


        return response
      });
      this.reminderModal = false;
    },

    getReminderData() {
      axios.get(process.env.VUE_APP_API_BASE_URL + "/reminder").then(response => {
        this.dataReminder = response.data
      });
    },

    openReminder() {
      this.reminderOpen = true;
      axios.get(process.env.VUE_APP_API_BASE_URL + "/reminder").then(response => {
        this.dataReminder = response.data;
      });
    },

    fromNow(date) {
      return moment(date).fromNow();
    },

    routeLinks(item) {
      this.routeIcons.forEach(e => {
        e.active = false
      })
      item.active = true
      this.$router.push(item.path);
    },

    sendData1() {
      this.dialog1 = false
      this.e1 = 1
    },

    sendData() {
      this.dialog = false
      this.e1 = 1
    }
  },

}
</script>

<style scoped>

.v-text-field--outlined fieldset {
  border: 1px solid #EDF0F5;
}

.buttonsCounter {
  width: 110px;
  box-shadow: 0px 0px 24px rgba(145, 163, 184, 0.12);
  border-radius: 16px;
  align-items: flex-end;
  bottom: 100px;
  background: white;
  margin-left: 180px !important;
}

.listActiveLi {
  background: #F4F7FC;
  border-radius: 14px;
}


.activeList {
  border-radius: 14px !important;
  color: black;
}

.lgNavigation {
  margin-top: 1px !important;
}

.sidebar {
  background: white !important;
  width: 340px !important;
}

.sidebar::-webkit-scrollbar {
  display: none !important;

}


.avatar {
  margin-top: 40px;
}


.navigationList {
  margin-top: 5px !important;
}

.logoDiv {
  margin-top: 25px;
}

.NavigationIcon {
  border-right: 3px solid #507BE8;
}


.appBar {
  margin-right: 20px;
  background: #FCFDFF !important;
}

.searchInput {
  width: 65px;
  margin-top: 25px;
  margin-right: 40px;
}


.toolsNav {
  margin-right: 20px;
}

.v-card > .v-card__progress + :not(.v-btn):not(.v-chip):not(.v-avatar), .v-card > :first-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
  display: none;
}

@media (min-width: 1850px) {
  .NavigationIcon {
    border-right: 3px solid #507BE8;
  }

  .calendar {
    width: 350px;
  }

  .searchNavInput {
    margin-right: 40px !important;
  }

  .welcome {
    margin-top: 45px !important;
    margin-left: 15px;
  }

  .magnatInfo {
    margin-top: 60px !important;
  }

  .appBar {
    margin-right: 80px;
  }

  .buttonsCounter {
    bottom: 50px;
    margin-left: 250px !important;
  }

  .sidebar {
    background: white !important;
    width: 400px !important;
  }


  .toolsNav {
    margin-top: 20px;
    margin-right: 40px;
  }


  .searchInput {
    max-width: 30% !important;
    margin-top: 50px;
  }

  .lgNavigation {
    margin-top: 30px !important;
  }

  .avatar {
    margin-top: 120px !important;
  }

  .navigationList {
    margin-top: 50px !important;
  }

  .logoDiv {
    margin-top: 50px;
  }

}

@media (min-width: 1600px) {
  .NavigationIcon {
    border-right: 3px solid #507BE8;
  }

  .calendar {
    border: 1px solid #EDF0F5;
    border-radius: 10px;
    padding: 2px;
  }


  .sidebar {
    background: white !important;
    width: 350px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .sidebar::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
  }

  .toolsNav {
    margin-top: 20px;
    margin-right: 40px;
  }


  .searchInput {
    max-width: 15% !important;
    margin-top: 50px;
    right: 20px;
    margin-right: 40px;
  }

  .lgNavigation {
    margin-top: 15px !important;
  }

  .avatar {
    margin-top: 80px;
  }
}

@media (max-width: 800px) {
  .lgNavigation {
    margin-top: 2px !important;
  }

  .iconNav {
    height: 25px !important;
  }

  .logoDiv {
    height: 30px !important;
  }


  
  .calendar .v-btn_content {
    font-size: 20px !important;
  }
}

</style>
